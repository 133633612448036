<template>
    <input :value="value"
      :id="id"
      class="input input-default jscolor-input {onFineChange:'jsColorOnFineChange(this)', borderColor:'var(--main-color-bg-2)', insetColor:'var(--main-color-bg-3)', backgroundColor:'var(--main-color-bg)', styleElement:'color-picker', overwriteImportant: true, required: true, hash: true}"
      @change="onChange($event.target)"
      @focus="showColorPicker"
      @onFineChange="onFineChange"
      ref="color_input"
      maxlength="7"
    />
</template>

<script>
  export default {
    name: 'jscolor',
    data () {
      return {
        color: this.value,
        rgb: undefined
      }
    },
    props: [
      'value',
      'id'
    ],
    methods: {
      onChange (target) {
        this.color = target.jscolor.toHEXString()
        this.$emit('input', this.color)
        this.$emit('finish', this.color)
        this.$refs.color_input.jscolor.hide()
      },
      onFineChange (e) {
        this.color = '#' + e.detail.jscolor
        this.rgb = e.detail.jscolor.rgb
        this.update()
        // this.$emit('input', this.color)
      },
      showColorPicker () {
        this.$refs.color_input.jscolor.show()
      },
      update () {
        if (!this.rgb) {
          this.rgb = convertHex(this.color)
        }
        const rgb = this.rgb
        function convertHex (hex) {
          hex = hex.replace('#', '')
          const r = parseInt(hex.substring(0, 2), 16)
          const g = parseInt(hex.substring(2, 4), 16)
          const b = parseInt(hex.substring(4, 6), 16)
          const result = [r, g, b]
          return result
        }

        function isLight () {
          return (
            0.213 * rgb[0] +
            0.715 * rgb[1] +
            0.072 * rgb[2] >
            255 / 2
          )
        };
        this.$refs.color_input.style.backgroundColor = this.color
        this.$refs.color_input.style.color = (isLight() ? '#000' : '#FFF')
      }
    },
    mounted: function () {
      window.jscolor.installByClassName('jscolor-input')
      this.update()
    },
    updated: function () {
      this.update()
    }
  }
  window.jsColorOnFineChange = function (thisObj) {
    thisObj.valueElement.dispatchEvent(new CustomEvent('onFineChange', {
      detail: {
        jscolor: thisObj
      }
    }))
  }
</script>
